<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Donors</span>
          <v-spacer></v-spacer>
        
          <v-flex xs12 align-self-center py-2>
            <v-layout wrap justify-end>
               
            </v-layout>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start v-if="donorsList.length > 0">
          <v-flex xs12>
            <v-simple-table class="align-center">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Donor Name</th>
                    <th class="text-left">Country</th>
                    <th class="text-left">Donor Type</th> 
                    <th class="text-left">Actions</th>
                  </tr>
                </thead>
                <tbody class="align-center">
                  <tr
                    class="text-left align-center"
                    v-for="(item, i) in donorsList"
                    :key="i"
                  >
                    <td>
                      <span
                        v-if="item.name"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.name }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.country"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.country }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.donorType"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.donorType }}</span
                      >
                    </td> 
                    <td>
                      <v-layout wrap>
                        <v-flex xs12 align-self-center text-left pa-2>
                          <v-dialog v-model="item.view" max-width="70%">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                title="View"
                                color="#005f32"
                                @click="getItems(item._id)"
                                v-on="on"
                                v-bind="attrs"
                                >mdi-eye</v-icon
                              >
                            </template>
                            <v-card>
                              <v-layout wrap>
                                <v-flex
                                  xs12
                                  align-self-center
                                  pa-4
                                  style="background: #005f32"
                                  text-left
                                >
                                  <span class="kumbhBold" style="color: #ffffff"
                                    >Donor Details</span
                                  >
                                </v-flex>
                                <v-flex
                                  xs12
                                  align-self-center
                                  class="kumbhRegular"
                                >
                                  <v-layout wrap pa-4>
                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                    >
                                      <span v-if="item.name">
                                        Donor Name : {{ item.name }}
                                      </span>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                    >
                                      <span v-if="item.donorType">
                                        Donor Type : {{ item.donorType }}
                                      </span>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                      v-if="item.email"
                                    >
                                      <span v-if="item.email">
                                        Email :
                                        {{ item.email }}
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                    >
                                      <span v-if="item.country">
                                        Country :
                                        {{ item.country }}
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 pa-2>
                                      <v-divider></v-divider>
                                    </v-flex>

                                     
                                    <v-flex
                                      xs12
                                      align-self-center
                                      text-left
                                      pa-4
                                    >
                                      <span class="kumbhBold"> Item(s) </span>
                                    </v-flex>

                                    <v-flex xs12 align-self-center>
                                      <v-layout wrap>
                                        <v-flex
                                          xs12
                                          sm6
                                          pa-1
                                          v-for="(split, s) in inventoryList"
                                          :key="s"
                                        >
                                          <v-card
                                            outlined
                                            class="rounded-lg pa-3 kumbhMedium"
                                          >
                                            <v-layout
                                              wrap
                                              pa-2
                                              text-left
                                              v-if="split.assetId.name"
                                            >
                                              <v-flex xs6 align-self-center>
                                                <span> Asset name </span>
                                              </v-flex>
                                              <v-flex xs6 align-self-center>
                                                <span>
                                                  {{ split.assetId.name }}
                                                </span>
                                              </v-flex>
                                            </v-layout>
                                            <v-layout
                                              wrap
                                              pa-2
                                              text-left
                                              v-if="split.code"
                                            >
                                              <v-flex xs6 align-self-center>
                                                <span> Asset category </span>
                                              </v-flex>
                                              <v-flex xs6 align-self-center>
                                                <span>
                                                  {{ split.code }}
                                                </span>
                                              </v-flex>
                                            </v-layout>

                                            <v-layout
                                              wrap
                                              pa-2
                                              text-left
                                              v-if="split.assetId.assetType"
                                            >
                                              <v-flex xs6 align-self-center>
                                                <span> Asset type </span>
                                              </v-flex>
                                              <v-flex xs6 align-self-center>
                                                <span>
                                                  {{ split.assetId.assetType }}
                                                </span>
                                              </v-flex>
                                            </v-layout>

                                             

                                             
                                           
                                            <v-layout
                                              wrap
                                              pa-2
                                              text-left
                                              v-if="split.quantity"
                                            >
                                              <v-flex xs6 align-self-center>
                                                <span> Quantity </span>
                                              </v-flex>
                                              <v-flex xs6 align-self-center>
                                                <span>
                                                  {{ split.quantity }}
                                                </span>
                                              </v-flex>
                                            </v-layout>
                                          </v-card>
                                        </v-flex>
                                      </v-layout>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>

                              <!-- <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="error"
                                  tile
                                  dark
                                  @click="item.view = false"
                                  >Cancel</v-btn
                                >
                                &nbsp;
                              </v-card-actions> -->
                            </v-card>
                          </v-dialog>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Donors found!</span>
          </v-flex>
        </v-layout>
 
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      totalData: 0,
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      donorsList: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      name: null,
      assetId: null,
      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      inventoryList:null,
    };
  },
  mounted() {
     
    this.getData();
  },
  watch: {
     
    currentPage() {
      this.getData()
    }
  },

  methods: {
     getItems(id) {
      this.inventoryList=null
      this.appLoading = true;
      axios({
        url: "/asset/inventoryStocks/list",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          donorId: id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.inventoryList = response.data.data; 
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/donor/list",
        method: "get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.currentPage,
          limit: 20, 
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.donorsList = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
